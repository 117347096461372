import Home from "../components/layouts/home";
import * as React from "react";
import { graphql } from "gatsby";

function createMarkup(input: string) {
  return { __html: input };
}

const Agb = ({ data }) => {
  return (
    <Home>
      <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl prose-yellow	max-w-screen-sm px-4 md:px-0 mb-8"
           dangerouslySetInnerHTML={createMarkup(data.allMarkdownRemark.nodes[0].html)} />
    </Home>
  );
};
export default Agb;
export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(\\/content\\/agb)/.*\\\\.md$/"}}) {
      nodes {
        html
      }
    }
  }
`;
